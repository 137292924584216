import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Config } from '../config/config';
import { Subject, BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { map, takeUntil, catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { AIChatHistory, UUID } from 'src/app/pages/aichat/aichat.model';
import { CreateSheetDto, SheetData } from 'src/app/pages/spreadsheet/spreadsheet.model';
import { HttPromises } from 'src/app/core/service/httPromises.service';
import { AIChat } from 'src/app/pages/aichat/aichat.component';
import { WebsocketService } from 'src/app/core/service/websocket.service';
import { StateObservable, StructureObservable } from './observable.service';
import { ToastService } from 'src/app/toast/toast.service';
import { StructureEvent } from './table-extractor.model';

@Injectable({
    providedIn: 'root',
})
export class TableExtractorService {

    apiUrl = Config.backend_url + '/table-extractor';
    private cancelRequestSubject = new Subject<void>();

    constructor(
        private http: HttpClient,
        private httPromises: HttPromises,
        private websocketService: WebsocketService,
        private toastService: ToastService,
        private stateObservable: StateObservable,
        private structureObservable: StructureObservable
    ) { }

    private showSuccess(message:any, permanent?: boolean) {
        this.toastService.showSuccess("Structurization successful",message, permanent);
    }

    private showError(error:any, permanent?: boolean) {
        this.toastService.showError("Structurization error",error, permanent);
    }

    private propagateEvent(status: StructureEvent['status'], tableId?: string, fileId?: string ) {
        const event:StructureEvent = { status, tableId, fileId };
        this.structureObservable.registerNewUpdate(event);
    }

    cancelRequest() {
        // TODO: Implement cancellation method
        this.cancelRequestSubject.next();
        this.stateObservable.setLoading(false);
    }

    request(tableId: string, fileId?: string): Observable<any> {

        this.stateObservable.setLoading(true);
        this.propagateEvent('all-start');
        
        const requestURI = `${this.apiUrl}/process/${tableId}`;
    
        return this.websocketService.connect().pipe(
            switchMap(socketId => {
                if (!socketId) {
                    console.error("WebSocket connection failed or no socketId received.");
                    this.stateObservable.setLoading(false);
                    return of(null);
                }
                const headers = {};
                const params = {};
                if (socketId) {
                    Object.assign(headers,{'x-socket-id':socketId});
                }
                if (fileId) {
                    Object.assign(params, { 'fileId': fileId });
                }
                return this.http.get(requestURI,{ headers, params }).pipe(
                    takeUntil(this.cancelRequestSubject),
                    map(res => {
                      if (res) {
                        this.showSuccess("All files have been structured.", true);
                      }
                      return res;
                    }),
                    catchError(error => {
                      console.error('Error during HTTP request:', error);
                      return throwError(error);
                    }),
                    finalize(() => {
                      this.stateObservable.setLoading(false);
                    })
                  );
            })
        );
    }

}