import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../core/config/config';
import { Subject } from 'rxjs';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { UserService } from '../core/service/user.service';


@Injectable({
    providedIn: 'root',
})
export class StripeService {
    apiUrl = Config.backend_url + '/stripe';
    productData:any = {};
    stripePromise: Promise<Stripe | null>;
    constructor(
        private http: HttpClient,
        private userService: UserService
    ) {
        this.stripePromise  = loadStripe(Config.stripe_pub_key)
    }

    redirectToCheckout(data: any) {
        return this.http.post(Config.backend_url + '/stripe/create-checkout-session', data)
    }
    getAllProducts() {
        return this.http.get(Config.backend_url + '/stripe/products')
    }
    getProductById(id: string) {
        return this.http.get(Config.backend_url + `/stripe/product/${id}`)
    }
    getAllPaymentLinks(){
        return this.http.post(Config.backend_url + '/stripe/links', {})
    }

    async checkout(billType:string, accounttType: string) {
        const stripe:any = await this.stripePromise;
        const accountId = parseInt(accounttType.split('t')[1], 10);

        this.productData = {
            bill: billType,
            account: accountId
        }
        this.redirectToCheckout(this.productData)
        .subscribe(async (response: any) => {
            console.log('response', response)
            await stripe.redirectToCheckout({
                sessionId: response.id,
            });
        })
    }

    async directRedirection(bill:string, account:string){
        const accountId = parseInt(account.split('t')[1], 10);
        let paymentLink = ''; 
        const startDate = new Date();
        const expirationDate = new Date(startDate);       
        if(accountId == 4){
            if(bill === 'month') {
                paymentLink = Config.individualPlan_month;
                expirationDate.setMonth(startDate.getMonth() + 1);
            } else {
                paymentLink = Config.individualPlan_year;
                expirationDate.setMonth(startDate.getMonth() + 12);
            }
        } else if(accountId == 5 ){
            if(bill === 'month') {
                paymentLink = Config.businessPlan_month;
                expirationDate.setMonth(startDate.getMonth() + 1);

            } else {
                paymentLink = Config.businessPlan_year;
                
            }
        }
        var data = {
            account : account,
            billing: bill,
            subscriptionExpireDate: expirationDate.getTime()
        };
        console.log('data', data)

        this.userService.updateUser(data).subscribe(res => {
            window.location.href = paymentLink;
        })
    }

    redirectToStripeCredit(){
        window.open(Config.purchaseCredits, '_blank');
        // window.open(Config.purchaseTestCredits, '_blank')
    }   

    purchaseCredits() {
        return this.http.post(`${this.apiUrl}/create-checkout-session`, {})
    }
}


