import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GoogleAnalyticsComponent } from '../integration/analytics/analytics.component';
import { AdsComponent } from '../integration/ads/ads.component';
import { ShopifyComponent } from '../integration/shopify/shopify.component';
import { WooComComponent } from '../integration/woocom/woocom.component';
import { HubspotComponent } from '../integration/hubspot/hubspot.component';
import { JobHistoryComponent } from '../integration/job-history/job-history.component';
import { DriveComponent } from '../integration/drive/drive.component';
import { IntegrationComponent } from './integration.component';

const routes: Routes = [
    {
        path: 'integration',
        component: IntegrationComponent,
    },
    {
        path: 'analytics',
        component: GoogleAnalyticsComponent,
    },
    {
        path: 'ads',
        component: AdsComponent,
    },
    {
        path: 'shopify',
        component: ShopifyComponent,
    },
    {
        path: 'woocom',
        component: WooComComponent,
    },
    {
        path: 'hubspot',
        component: HubspotComponent,
    },
    {
        path: 'drive',
        component: DriveComponent,
    },
    {
        path: 'job-history/:type/:id',
        component: JobHistoryComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ],
})
export class IntegrationRoutingModule { }
