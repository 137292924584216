<div>
    <div class="">
        <div id="content" class="row">
            <!-- SIDEBAR -->
            <div class="sidebar col-lg-2 col-md-2 col-sm-4" fileDragDrop [allowedMimeTypes]="valid_MIME_types"
                [allowedExtensions]="valid_extensions" (filesChangeEmitter)="onItemDrop($event,true)" #fileUpload>
                <div class="new-workspace" (click)="toggleSidebarItemCollapse()">
                    <div class="logo">
                        <img src="../../../assets/img/vhi_logo.png" />
                        <img src="../../../assets/img/icons/talonic_icon.png" />
                    </div>
                </div>
                <div class="dragover-data">
                    <lord-icon src="../../../assets/img/icons/drop.json" trigger="loop"></lord-icon>
                    <p>
                        Drop your data<br>
                    </p>
                </div>
                <div class="sidebar-wrapper">
                    <div class="new-sidebar-item" (click)="newWorkspaceOrDataset('', 'dataspace')" (mouseenter)="showTooltip()"
                        (mouseleave)="hideTooltip()" placement="bottom">
                        <a id="new-sheet">
                            <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="loading"></span>
                        </a>
                    </div>
                    <div class="index-item dataspace" (click)="toggleSidebarItemCollapse('dataspace')">
                        <lord-icon src="../../../assets/img/icons/data.json" trigger="hover"></lord-icon>
                        <label>Data</label>
                    </div>

                    <div class="index-wrapper dataspace-mini-index">
                        <section *ngIf="uploadFileLoading" class="uploading">
                            <a>
                                <lord-icon class="data-source-type-icon loading"
                                    src="../../../assets/img/icons/circle.json" trigger="loop"></lord-icon>
                                <div class="table-item">
                                    <div>{{fileName}}</div>
                                </div>
                            </a>
                        </section>
                        <section *ngFor="let _table of getDataspaces(); let index = index " [draggable]="true"
                            (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
                            <a [ngClass]="{ 'active': _table.tableId == activeTable }" *ngIf="!_table.containerId">
                                <div class="data-source-type-icon" [ngClass]="_table.file?.extension"></div>
                                <div class="table-item" (click)="getSpreadSheet(_table);">
                                    <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                    <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                        (keydown.enter)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                        (click)="$event.stopPropagation()" />
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="_table.loading"></span>
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <div
                                            (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                            <i class="bi bi-pencil"></i> Rename
                                        </div>
                                        <div (click)="duplicateTable(_table)">
                                            <i class="bi bi-copy"></i> Duplicate
                                        </div>
                                        <div (click)="confirm(deleteTableModel,_table.tableId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteTableModel"></i> Delete
                                        </div>
                                    </span>
                                </span>
                            </a>
                        </section>
                        
                    </div>

                    <!-- FILE IMPORT UI -->
                    <label class="drop-import" placement="bottom">
                        <input type="file" class="file-input" (change)="onItemDrop($event)" #fileUpload [accept]="valid_files">
                        <lord-icon src="../../../assets/img/icons/cloud-upload.json" trigger="hover"></lord-icon>
                        <span *ngIf="!fileName">
                            Click here or drop<br>files to upload
                        </span>
                        <span *ngIf="fileName">{{fileName}}</span>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="uploadFileLoading"></span>
                    </label>

                </div>
            </div>

            <div class="detail-sidebar dataspace-sidebar">
                <div class="index-item dataspace" (click)="toggleSidebarItemCollapse('dataspace')">
                    <lord-icon src="../../../assets/img/icons/data.json" trigger="hover"></lord-icon>
                    <label>Data</label>
                </div>
                <div class="new-workspace" (click)="newContainer()" placement="bottom">
                    <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                    <span>New Folder</span>
                    <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                </div>
                <div class="index-wrapper dataspace-index">
                    <section *ngFor="let _table of getDataspaces(); let index = index " [draggable]="true"
                        (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
                        <a [ngClass]="{ 'active': _table.tableId == activeTable }" *ngIf="!_table.containerId">
                            <div class="data-source-type-icon" [ngClass]="_table.file?.extension"></div>
                            <div class="table-item" (click)="getSpreadSheet(_table);">
                                <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                    (keydown.enter)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                    (click)="$event.stopPropagation()" />
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="_table.loading"></span>
                            </div>
                            <span ngbDropdown>
                                <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i
                                        class="bi bi-three-dots"></i></button>
                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <div
                                        (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                        <i class="bi bi-pencil"></i> Rename
                                    </div>
                                    <div (click)="duplicateTable(_table)">
                                        <i class="bi bi-copy"></i> Duplicate
                                    </div>
                                    <div (click)="confirm(deleteTableModel,_table.tableId)">
                                        <i class="bi bi-trash" data-bs-toggle="modal"
                                            data-bs-target="#deleteTableModel"></i> Delete
                                    </div>
                                </span>
                            </span>
                        </a>
                    </section>
                    <section *ngFor="let _container of containers">
                        <div class="folder" *ngIf="!_container.isContainerNameEdit">
                            <a class="folder" (dblclick)="doubleClickEditContainer(_container)">
                                <lord-icon src="../../../assets/img/icons/folder.json"
                                    trigger="hover"></lord-icon>{{_container.containerName}}
                            </a>
                            <span (click)="toggleAccordion(_container)">
                                <i *ngIf="_container.toogleChevron" class="bi bi-chevron-down"></i>
                                <i *ngIf="!_container.toogleChevron" class="bi bi-chevron-up"></i>
                            </span>

                            <span ngbDropdown>
                                <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                        class="bi bi-three-dots"></i></button>
                                <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                    <div (click)="newWorkspaceOrDataset(_container.containerId)">
                                        <i class="bi bi-plus"></i> New Dataset
                                    </div>
                                    <div (click)="confirm(deleteContainerModel,_container.containerId)">
                                        <i class="bi bi-trash" data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"></i> Delete
                                    </div>
                                    <div (click)="openGear(openFolderGearModal,_container.containerId)">
                                        <i class="bi bi-gear" data-bs-toggle="modal"
                                            data-bs-target="#openFolderGearModal"></i> Settings
                                    </div>
                                </span>
                            </span>
                        </div>
                        <span *ngIf="_container.isContainerNameEdit">
                            <a class="folder">
                                <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                                <input [(ngModel)]="_container.editedContainerName"
                                    (focusout)="stopEditContainer(_container)" />
                            </a>
                        </span>
                        <section *ngIf="!_container.toogleChevron">
                            <a class="in-folder" *ngFor="let _table of _container.tables ; let index = index "
                                [draggable]="true" (dragstart)="onDragStart(index, _container.containerId)"
                                (dragenter)="onDragEnter(index, _container.containerId)" (dragend)="onDragEnd()"
                                [ngClass]="{ 'active': _table.tableId == activeTable }">
                                <!--<lord-icon src="../../../assets/img/icons/sheet.json" trigger="hover"></lord-icon>-->
                                <div class="table-item" (click)="getSpreadSheet(_table);">
                                    <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                    <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                        (blur)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                        (click)="$event.stopPropagation()" />
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                        <div
                                            (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                            <i class="bi bi-pencil"></i> Rename
                                        </div>
                                        <div (click)="duplicateTable(_table)">
                                            <i class="bi bi-copy"></i> Duplicate
                                        </div>
                                        <div (click)="confirm(deleteTableModel,_table.tableId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteTableModel"></i> Delete
                                        </div>
                                        <div (click)="openGear(openDatasetGearModal, _table.tableId)">
                                            <i class="bi bi-gear" data-bs-toggle="modal"
                                                data-bs-target="#openDatasetGearModal"></i> Settings
                                        </div>
                                    </span>
                                </span>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="_table.loading"></span>
                            </a>
                        </section>
                    </section>
                </div>
            </div>

            <!-- LEFT SLIDE -->
            <div #chatSlide class="chat slide" [ngClass]="signedUrlForPDF||showRawFileData||signedUrlForImage||showDownloadIconForFileUpload ? 'min' : 'full'">
                <!-- CHAT -->
                <div class="dragover-data" 
                    *ngIf="!fileData.filename && !!isNewDataspaceCreated" 
                    fileDragDrop 
                    [allowedMimeTypes]="valid_MIME_types"
                    [allowedExtensions]="valid_extensions"
                    (filesChangeEmitter)="onItemDrop($event,true,true)"
                    #fileUpload
                    >
                    <label class="drop-import" placement="bottom">
                        <input type="file" class="file-input visually-hidden" (change)="onItemDrop($event, false, true)" #fileUpload [accept]="valid_files">
                        <lord-icon src="../../../../assets/img/icons/cloud-upload.json" trigger="hover"></lord-icon>
                        <p>Drop your data<br></p>
                    </label>
                </div>
                <ng-container *ngIf="signedUrlForImage">
                    <app-spreadsheetsource [signedUrl]="signedUrlForImage"></app-spreadsheetsource>
                </ng-container>      
                <ng-container *ngIf="signedUrlForPDF && !isNewDataspaceCreated">
                    <app-spreadsheetsource [signedUrl]="signedUrlForPDF"></app-spreadsheetsource>
                </ng-container>
                <ng-container *ngIf="showRawFileData && !isNewDataspaceCreated">
                    <app-spreadsheetsource [rawFileData]="dataForUploadFiles"></app-spreadsheetsource>
                </ng-container>
                <ng-container *ngIf="tableHasFile">
                    <div *ngIf="files.length > 0" class="file-picker">
                        <div class="file-picker-file" *ngFor="let file of files; index as file_index" (click)="selectFile(file_index)" [ngClass]="{ 
                            'selected': selected_file_index==file_index,
                            'queued': file.status == 'queued',
                            'processing': file.status == 'processing',
                            'failed': file.status == 'failed',
                            'finished': file.status == 'finished',
                            'structured': file.isStructured
                            }"
                        >
                            <div class="file-picker-container">
                                <img src="{{ file.image?.url }}" alt="{{ file.fileName }}"/>
                                <div class="data-source-type-icon" [ngClass]="file.extension"></div>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <lord-icon class="finished" src="../../../../assets/img/icons/check_done.json" trigger="hover"></lord-icon>
                                <lord-icon class="failed" src="../../../../assets/img/icons/warning.json" trigger="hover"></lord-icon>
                                <lord-icon class="structured" src="../../../../assets/img/icons/check_purple.json" trigger="hover" title="This file is already structured."></lord-icon>
                            </div>
                            <div class="file-picker-popup">{{ file.fileName }}</div>
                        </div>
                        <ng-container *ngIf="filesCurrentlyUploading > 0">
                            <div class="file-picker-uploading" *ngFor="let _ of uploadingSlots; let i = index" [ngClass]="i===0?'active':''">
                                <div class="file-picker-container">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </div>
                            </div>
                        </ng-container>
                        <div class="file-picker-upload"
                            fileDragDrop 
                            [allowedMimeTypes]="valid_MIME_types"
                            [allowedExtensions]="valid_extensions"
                            (filesChangeEmitter)="onSubItemDrop($event,true)"
                            #fileUpload
                        >
                            <label class="drop-import" placement="bottom">
                                <input type="file" class="file-input visually-hidden" (change)="onSubItemDrop($event, false)" #fileUpload [accept]="valid_files">
                                <lord-icon src="../../../../assets/img/icons/cloud-upload.json" trigger="hover"></lord-icon>
                            </label>
                            <div class="file-picker-popup">Upload more files</div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!-- RIGHT SLIDE -->
            <div #tableSlide class="table slide">
                <div class="sub-header">
                    <div class="sub-head">
                        <div class="dropdown" *ngFor="let sheet of sheets; let i = index"
                            [attr.data-aria-labelledby]="'dropdownMenuButton_' + i">
                            <button class="btn btn-primary dropdown-toggle sheet_dd" type="button"
                                id="dropdownMenuButton_{{i}}" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" [class.loaded]="sheet.loaded"
                                (click)="switchSheetIfDifferent(sheet)">
                                <span *ngIf="!sheet.isSheetNameEdit">{{sheet.name}}</span>
                                <input *ngIf="sheet.isSheetNameEdit" [(ngModel)]="sheet.editedSheetName"
                                    (focusout)="stopEditingSheet(sheet)" (click)="$event.stopPropagation()" />
                            </button>
                            <div class="dropdown-popup" *ngIf="sheet.showDropDown">
                                <input type="file" class="file-input" (change)="uploadsheet($event, sheet)" #fileUpload>
                                <a class="dropdown-item" (click)="fileUpload.click()"><i
                                        class="bi bi-cloud-upload"></i>Import Data</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="editSheetName(sheet)"><i
                                        class="bi bi-pencil"></i>Rename sheet</a>
                                <a class="dropdown-item" (click)="duplicateSheet(sheet)"><i
                                        class="bi bi-copy"></i>Duplicate sheet</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteRecordModal"
                                    (click)="confirm(deleteModel,sheet.id)"><i class="bi bi-trash"></i>Delete sheet</a>
                            </div>
                        </div>
                        <div class="icon" id="add-sheet" (click)="createNewSheet()">
                            <lord-icon src="../../../assets/img/plus.json" trigger="hover" placement="top"
                                ngbTooltip="Create New Sheet"></lord-icon>
                        </div>
                        <!--<div class="flex-box right table-interactions">
                            <div class="csv-export" (click)="downloadCsv()">
                                <lord-icon src="../../../assets/img/google-sheet-white.json" trigger="hover"></lord-icon>CSV
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="downloadLoading.csv"></span>
                            </div>
                            <div class="excel-export" (click)="downloadXls()">
                                <lord-icon src="../../../assets/img/excel.json" trigger="hover"></lord-icon>Excel
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="downloadLoading.xls"></span>
                            </div>
                            <div class="share" (click)="resetTable()">
                                <lord-icon src="../../../assets/img/icons/share.json" trigger="hover"></lord-icon>Share
                            </div>
                        </div>-->
                    </div>
                    <div class="export-spreadsheet share-workspace" data-bs-toggle="modal"
                        (click)="alert(activeSheet?.id)" placement="left">
                        <lord-icon src="../../../assets/img/icons/share.json" trigger="hover"></lord-icon>
                    </div>
                    <div class="export-spreadsheet clean-data" (click)="cleanSpreadsheet()">
                        <lord-icon src="../../../assets/img/icons/clean.json" trigger="hover"></lord-icon>
                    </div>
                    <div class="export-spreadsheet" data-bs-toggle="modal" data-bs-target="#openDownloadModal"
                        (click)="openModal(openDownloadModal)" placement="left">
                        <lord-icon src="https://cdn.lordicon.com/emlqoqnv.json" trigger="hover"
                            colors="primary:#000000,secondary:#000000"></lord-icon>
                    </div>
                </div>
                <div id="x-spreadsheet-demo" (click)="initXSSpreadsheetEvents()"></div>
                <ng-container *ngIf="files_unstructured_count > 0">
                    <button *ngIf="!spreadSheetLoading && files_unstructured_count == 1" type="button" class="btn btn-success fixed-button" (click)="structureData()">Structure File</button>
                    <button *ngIf="!spreadSheetLoading && files_unstructured_count > 1" type="button" class="btn btn-success fixed-button" (click)="structureData()">Structure {{files_unstructured_count}} Files</button>
                    <button *ngIf="spreadSheetLoading" type="button" class="btn btn-success fixed-button disabled">Structuring..</button>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- removeItemModal -->
<ng-template #deleteModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you Sure You want to Remove this Sheet?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="deleteItem(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<ng-template #deleteTableModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you Sure You want to Remove this table?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="deleteTable(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<ng-template #deleteFileModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you sure you want to remove this file?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="fileDelete(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- removeItemModal -->
<ng-template #deleteContainerModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure You want to Remove this Folder ?</h4>
                    <p class="text-muted mx-4 mb-0">All dataset in it will be deleted.</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product"
                    (click)="deleteContainer(deleteId)" (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- dataset setting -->
<ng-template #openDatasetGearModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Settings Dataset</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product"
                    (click)="deleteContainer(deleteId)" (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- folder settings -->
<ng-template #openFolderGearModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Settings Folder</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product"
                    (click)="deleteContainer(deleteId)" (click)="modal.close('Close click')">Yes</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- Integration Modal settings -->
<ng-template #openAddIntegrationModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4>Add Integrations</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="integration-list">
                <div class="integration-item">
                    <div class="i-logo"><img src="../../../assets/img/integration-logos/google-sheets.png" /></div>
                    <div class="i-name">Google OAuth Integration</div>
                    <button class="i-authenticate"><a [href]="googleAuthUrl">Authenticate</a></button>
                </div>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- download modal -->
<ng-template #openDownloadModal let-modal>
    <div class="modal-content download-dataset">
        <div class="modal-header">
            Download Dataset
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="download-buttons">
                    <button type="button" class="btn btn-secondary" (click)="downloadXls()">
                        <lord-icon src="../../../assets/img/icons/excel.json" trigger="hover"></lord-icon>Download XLS
                    </button>
                    <button type="button" class="btn btn-secondary" (click)="downloadCsv()">
                        <lord-icon src="../../../assets/img/icons/sheet.json" trigger="hover"></lord-icon>Download CSV
                    </button>
                </div>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>