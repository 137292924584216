import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CognitoService } from '../core/service/cognito.service';
import { UserService } from 'src/app/core/service/user.service';
import { IUser } from 'src/app/core/models/user.model';
import { StripeService } from 'src/app/stripe/stripe.services';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  loading: boolean = false;
  user: IUser = {} as IUser;
  userForm!: FormGroup;
  userSubscriptionTier: string = 'Basic';
  isEditingBillingAddress: boolean = false;
  userAvatarUrl: string | null = null;
  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private cognitoService: CognitoService,
    private userService: UserService,
    private stripeService: StripeService,
    private toastService: ToastService
    ) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.loadUserData();
    this.loadUserAvatar();

  }

  loadUserData() {
    this.userService.getUser().subscribe({
      next: (userData: any) => {
        this.user = userData;
        this.updateFormWithUserData(userData);
      },
      error: (error) => console.error('Error fetching user data:', error)
    });
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      name: [''],
      email: [''],
      organization: [''],
      address: [''],
      zipcode: [''],
      city: [''],
      country: ['']
    });
  }


  async loadUserAvatar() {
    this.userService.getUserAvatar().subscribe((response: any) => {
        const imageBuffer = response.data.Body.type === 'Buffer' ? response.data.Body : null;
        if (imageBuffer) {
          this.userAvatarUrl = `data:${response.data.ContentType};base64,${this.bufferToBase64(imageBuffer)}`;
        }
    });
  }

  // Convert Buffer to Base64
  bufferToBase64(buffer: any): string {
    const binaryString = [];
    const bytes = new Uint8Array(buffer.data);
    for (let i = 0; i < bytes.byteLength; i++) {
      binaryString.push(String.fromCharCode(bytes[i]));
    }
    return btoa(binaryString.join(''));
  }

  onAvatarChange(event: any) {
    
    const formData: any = new FormData();
    if(event.target){
      // console.log('event.target', event.target)
      formData.append('file', event.target.files[0]);
    }
    
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.userAvatarUrl = e.target.result;
        this.uploadAvatar(formData);
      };
      reader.readAsDataURL(file);
    }
  }

  uploadAvatar(file: File) {
    this.userService.uploadAvatar(file).subscribe({
      next: (data: any) => {
        console.log('data', data)
      },
      error: (error) => console.error('Error fetching user data:', error)
    });
  }

  private updateFormWithUserData(userData: any) {
    this.userForm.patchValue(userData);
  }

  toggleBillingAddressEdit() {
    this.isEditingBillingAddress = !this.isEditingBillingAddress;
  }

  saveField() {
    console.log('this.userform', this.userForm.value);
    this.userService.updateUser(this.userForm.value).subscribe({
      next: (res: Object) => {
        console.log('res', res);
        this.updateFormWithUserData(res);
        this.isEditingBillingAddress = false;
        this.toastService.showSuccess('success', 'User updated successfully');
      },
      error: (error) => {
        console.error('Error updating user:', error);
        this.toastService.showError('error', 'Failed to update user');
      }
    });
  }

  saveBillingAddress() {
    const billingAddress = {
      address: this.userForm.get('address')?.value ?? '',
      zipcode: this.userForm.get('zipcode')?.value ?? '',
      city: this.userForm.get('city')?.value ?? '',
      country: this.userForm.get('country')?.value ?? ''
    };
    this.updateUser(billingAddress);
    this.toggleBillingAddressEdit();
  }

  updateUser(updatedFields: any) {
    this.userService.updateUser(updatedFields).subscribe({
      next: (response) => {
        this.updateFormWithUserData(response);
        this.toastService.showSuccess('success', 'User updated successfully');
        console.log('User updated successfully:', response);
      },
      error: (error) => {
        console.error('Error updating user:', error);
      }
    });
  }

  async update(): Promise<void> {
    this.loading = true;

    await this.cognitoService.updateUser(this.user);
    this.userService.updateUser(this.user);
    this.loading = false;
    window.sessionStorage.setItem('user', JSON.stringify(this.user));
  }

  getUserInitial() {
    return this.cognitoService.getLoggedInUserName()[0].toUpperCase();
  }

  getUserDetails() {
    this.loading = true;
    this.userService.getUser().subscribe({
      next: (userData: Object) => {
        this.user = userData as IUser;
        this.loading = false;
        this.userSubscriptionTier = this.getSubscriptionTierName(parseInt(this.user.subscriptionTier.split('t')[1]));
      }
    });
  }

  purchaseCredits() {
    this.stripeService.purchaseCredits().subscribe(() => {this.stripeService.redirectToStripeCredit()});
  }

  onSubmit() {
    // This method is not used in this implementation, but you can add form submission logic here if needed
  }

  getSubscriptionTierName(tier: number): string {
    switch (tier) {
      case 0:
        return 'Freemium';
      case 1:
        return 'Individual';
      case 2:
        return 'Business';
      case 3:
        return 'Premium';
      case 4:
        return 'Enterprise';
      case 5:
        return 'Admin';
      case 6:
        return 'Talonic Staff';
      default:
        return 'Unknown';
    }
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click(); // Programmatically trigger the file input click
  }
}