<div>

    <div class="card col-md-6">
        <div class="card-header">
            Hubspot
        </div>
        <div class="card-body">
            <a (click)="hubspotOauth()">Login to Hubspot</a>
        </div>
        <div class="card-body">
            <h5 class="card-title">Add Details</h5>
            <input type="text" id="credentials_title" class="form-control form-control-sm" placeholder="Private App Name" [(ngModel)]="credentials_title" #ctrl="ngModel" required>
            <input type="text" id="access_token" class="form-control form-control-sm" placeholder="Access Token" [(ngModel)]="access_token" #ctrl="ngModel" required>
            <!--
            <input type="text" id="clienId" class="form-control form-control-sm" placeholder="Client Id" [(ngModel)]="clientId" #ctrl="ngModel" required>
            <input type="text" id="clientSecret" class="form-control form-control-sm" placeholder="Client Secret" [(ngModel)]="ClientSecret" #ctrl="ngModel" required>
            -->
            <button type="button" class="btn btn-primary" (click)="createHubspot()">Submit</button>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
        </div>
    </div>

    <!-- 
    <div class="row" *ngIf="jobHistory && jobHistory.length">
        <div class="col-md-6">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Start Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let _jh of jobHistory">
                        <td>{{_jh.createdOn | date: 'dd-MM-yyyy'}}</td>
                        <td>{{_jh.jobStatus}}</td>
                        <td>{{_jh.jobType}}</td>
                        <td (click)="checkJobStatus(_jh.jobId)"><a href="">Refresh</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div> -->