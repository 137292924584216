import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { Config } from '../config/config';
import { AIChatObservable, StructureObservable } from './observable.service';
import { AIEventType, AIEvent } from 'src/app/pages/aichat/aichat.model';
import { EventType, ProcessingStatus, WebsocketMessage } from 'src/app/core/models/websocket.model';
import { XSSheet } from 'src/app/pages/spreadsheet/spreadsheet.model';
import { StructureEvent } from './table-extractor.model';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  
  constructor(
    private aiChatObservable: AIChatObservable,
    private structureObservable: StructureObservable
    ) {}

  private propagateAIEvent<T>(type:EventType, data:T) {
      const event:AIEvent = { type: type, data };
      this.aiChatObservable.registerNewUpdate(event);
  }

  private propagateStructureEvent(data: StructureEvent) {
    this.structureObservable.registerNewUpdate(data);
}

  private createConnection(): Socket {
    return io(Config.websocket_url,{
      reconnectionAttempts: 1,
      reconnectionDelayMax: 1000,
      timeout: 5000
    });
  }

  connect(): Observable<string | null> {
    const socket: Socket = this.createConnection();
    const socketIdSubject = new Subject<string | null>();
    let sheetId:string = '';

    socket.on('connect', () => {
      console.log('WebSocket connection established');
    });

    socket.on('disconnect', () => {
      console.log('WebSocket connection disconnected');
      socketIdSubject.next(null);
      socketIdSubject.complete();
      this.propagateAIEvent('progress',null);
    });

    socket.on('error', (error: any) => {
      console.error('WebSocket error:', error);
      socketIdSubject.next(null);
      socketIdSubject.complete();
    });

    // Listen for socketId from the server
    socket.on('socketId', (socketId: string) => {
      socketIdSubject.next(socketId);
      socketIdSubject.complete();
    });

    // Listen for updates from the server
    socket.on('progress', (data: ProcessingStatus) => {
      this.propagateAIEvent('progress',data);
    });
    socket.on('update', (data: any) => {
      this.propagateAIEvent('update',data);
    });
    socket.on('text_stream', (data: string[]) => {
      this.propagateAIEvent('text_stream',data);
    });
    socket.on('table_stream', (data: XSSheet) => {
      this.propagateAIEvent('table_stream',data);
    });
    socket.on('redirect', (data: string) => {
      sheetId = data;
      this.propagateAIEvent('redirect',data);
    });
    socket.on('structure', (data: StructureEvent) => {
      this.propagateStructureEvent(data);
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
      socketIdSubject.next(null);
    });

    // Timeout for receiving socketId
    const timeout$ = timer(5000).pipe(takeUntil(socketIdSubject));
    timeout$.subscribe(() => {
      if (!socketIdSubject.closed) {
        console.log("WebSocket connection timed out waiting for socketId");
        socketIdSubject.next(null); // Emit null on timeout
        socketIdSubject.complete();
        socket.disconnect(); // Close the connection on timeout
      }
    });

    return socketIdSubject.asObservable();
  }

}
