import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    apiUrl = Config.backend_url + '/users';
    constructor(
        private http: HttpClient
    ) { }

    createUser(data: any) {
        return this.http.post(`${this.apiUrl}/register`, data);
    }

    loginUser(){
        return this.http.post(`${this.apiUrl}/login`, {});
    }

    updateUser(data:any){
        return this.http.patch(this.apiUrl, data);
    }

    updateUserCredits(data:any){
        return this.http.patch(`${this.apiUrl}/credits`, data);
    }

    updateMapping(data:any){
        return this.http.patch(`${this.apiUrl}/mapping`, data);
    }

    getUser(){
        return this.http.get(this.apiUrl);
    }

    getProfile() {
        return this.http.get(`${Config.backend_url}/profile`);
    }

    googleIntegration(){
        return this.http.post(`${this.apiUrl}/integration`, {});
    }

    getUserAvatar(): Observable<string> {
        return this.http.get<string>(`${this.apiUrl}/avatar`);
    }
    
    uploadAvatar(file: File): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.append('Accept', '*/*');
        return this.http.post(`${this.apiUrl}/avatar`, file)
    }
}