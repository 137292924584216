<div class="container mt-3">
    <div class="row">
        <div class="card col-md-6">
            <div class="card-header">
                Hubspot
            </div>
            <div class="card-body">
                <a (click)="hubspotOauth()">Login to Hubspot</a>
            </div>
            <div class="card-body">
                <h5 class="card-title">Add Details</h5>
                <input type="text" id="clienId" class="form-control form-control-sm" placeholder="Client Id"
                    [(ngModel)]="clientId" #ctrl="ngModel" required>
                <input type="text" id="clientSecret" class="form-control form-control-sm" placeholder="Client Secret"
                    [(ngModel)]="ClientSecret" #ctrl="ngModel" required>
                <button type="button" class="btn btn-primary" (click)="createHubspot()">Submit</button>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row p-2">
            <div class="col-md-12">
                <h4></h4>

            </div>
        </div>
    </div>
</div>