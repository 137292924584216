import { Component, OnInit, Input , SimpleChanges, OnChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Spreadsheet, { Options as SpreadsheetOptions, SheetData } from "x-data-spreadsheet";
import { SpreadsheetExt } from "../spreadsheet/spreadsheet.component";
import { GetTableDto } from "../spreadsheet/spreadsheet.model";

@Component({
    selector: 'app-spreadsheetsource',
    templateUrl: './spreadsheetsource.component.html',
})
export class SpreadSheetSourceComponent implements OnInit, OnChanges {
    @Input() signedUrl: string = ''; 
    @Input() rawFileData: GetTableDto = {};
    
    safeUrl: SafeResourceUrl = '';
    spreadsheetS3!: Spreadsheet;
    spreadSheetData: any = "";
    isImage = false;

    constructor(
        private sanitizer: DomSanitizer
    ){}

    ngOnInit(): void {
        this.changeSafeUrl();
        if(!this.signedUrl){
            this.spreadsheetS3 = this.initSpreadsheetS3();
            this.spreadsheetS3.loadData(this.spreadSheetData || {});
            if(this.rawFileData && this.rawFileData.data){
                this.spreadsheetS3.loadData(this.rawFileData?.data.XSheets);
            }
        }
    }

    changeSafeUrl() {
        if (!this.signedUrl) {
            this.safeUrl = '';
        } else {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.signedUrl);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['signedUrl']) {
          this.changeSafeUrl();
        }
      }

    initSpreadsheetS3(): SpreadsheetExt {
        return new SpreadsheetExt("#x-spreadsheet-s3",{
            mode: "read",
            row: {
                len: 1000,
                height: 25,
              },
            col: {
            len: 100,
            width: 100,
            indexWidth: 60,
            minWidth: 60,
            },
            showToolbar: false,
            showBottomBar: false,
            style: {
                bgcolor: '#ffffff',
                align: 'left',
                valign: 'middle',
                textwrap: false,
                strike: false,
                underline: false,
                color: '#0a0a0a',
                font: {
                    // @ts-ignore
                    name: "proxima-nova",
                    size: 10,
                    bold: false,
                    italic: false
                }
            }
        });
    }
}