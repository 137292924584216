import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';

@Component({
    selector: 'app-integration',
    templateUrl: './integration.component.html'
})

export class IntegrationComponent implements OnInit {

    googleOAuthUser: any;

    constructor(
        private airbyteService: AirByteService,
        private route: ActivatedRoute,
        private googleService: GoogleService,
        private router: Router
    ) {
        console.log('intgration component')
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                console.log('Current URL:', this.router.url);
            }
        });
    }


    ngOnInit(): void {
        
    }

    getCurrentPage(): string {
        // Get the current route
        const currentRoute = this.router.url.split('/').pop(); // Get the last segment of the URL
        // Return a formatted name based on the route
        switch (currentRoute) {
          case 'analytics':
            return 'Google Analytics';
          case 'ads':
            return 'Google Ads';
          case 'shopify':
            return 'Shopify';
          case 'woocom':
            return 'WooCommerce';
          case 'hubspot':
            return 'HubSpot';
          case 'drive':
            return 'Drive';
          case 'job-history':
            return 'Job History'; // You may want to adjust this for params
          default:
            return 'Account Overview'; // Default page title
        }
      }

}
