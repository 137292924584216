
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationComponent } from './integration.component';
import { GoogleAnalyticsComponent } from './analytics/analytics.component';
import { AdsComponent } from './ads/ads.component';
import { ShopifyComponent } from './shopify/shopify.component';
import { WooComComponent } from './woocom/woocom.component';
import { HubspotComponent } from './hubspot/hubspot.component';
import { JobHistoryComponent } from './job-history/job-history.component';
import { DriveComponent } from './drive/drive.component';
import { IntegrationRoutingModule } from './integration-routing.module';

@NgModule({
  declarations: [
    IntegrationComponent,
    GoogleAnalyticsComponent,
    AdsComponent,
    ShopifyComponent,
    WooComComponent,
    HubspotComponent,
    JobHistoryComponent,
    DriveComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    RouterModule,
    IntegrationRoutingModule,
  ]
})
export class IntegrationModule { }
