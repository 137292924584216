import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import lottie from "lottie-web";
import { defineElement } from '@lordicon/element';

import { SpreadSheetComponent } from 'src/app/pages/spreadsheet/spreadsheet.component';
import { AIChatMessageComponent } from './aichatmessage.component';

import { XSTable } from '../spreadsheet/spreadsheet.model';
import { AISource, AIChatMessage, AIEvent, AIChatHistory } from './aichat.model';

import { AIChatService } from 'src/app/core/service/aichat.service';
import { TablesService } from 'src/app/core/service/table.service';
import { StateObservable, AIChatObservable, AIAudioInputObservable } from 'src/app/core/service/observable.service';
import { ToastService } from 'src/app/toast/toast.service';

defineElement(lottie.loadAnimation);

@Component({
    selector: 'ai-chat',
    templateUrl: './aichat.component.html',
    styleUrls: ['./aichat.component.scss']
})
export class AIChat implements OnInit , AfterViewInit {

    @ViewChild('contentContainer') contentContainer: ElementRef | undefined;
    @Input() isFeature!: boolean;

    private loadingSubscription!: Subscription;
    private audioSubscription!: Subscription;

    loading: boolean = false;
    showLoading: boolean = false;
    showLoadingTimer: NodeJS.Timeout|undefined;
    visible: boolean = true;
    suggestions: string[] = [];
    sources: AISource[] = [];
    conversation: AIChatMessage[] = [];//this.aiChatService.getConversation();
    inputChat: string = '';
    isMobile: boolean = false;
    resetTable:any;
    status: string = '';
    response: string[] = [];

    constructor(
        private aiChatService: AIChatService,
        private tablesService: TablesService,
        private toastService: ToastService,
        private stateObservable: StateObservable,
        private aiChatObservable: AIChatObservable,
        private aiAudioInputObservable: AIAudioInputObservable,
        private spreadsheetComponent: SpreadSheetComponent,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.isMobile = window.innerWidth <= 599;
        this.subscribeToStateObservable();
        this.subscribeToAIChatObservable();
        this.subscribeToAIAudioInputObservable();
        this.tableChats();
    }

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        if(!this.contentContainer) return;
        this.changeDetectorRef.detectChanges();
        const element = this.contentContainer.nativeElement;
        if (element) {
          element.scrollTop = element.scrollHeight;
        }
      }

    subscribeToStateObservable() {
        this.loadingSubscription = this.stateObservable.loading$.subscribe(loading => {
            if (loading) {
                this.lockInput();
            } else {
                this.unlockInput();
                this.unsetShowLoading();
            }
        });
    }

    subscribeToAIAudioInputObservable() {
        this.audioSubscription = this.aiAudioInputObservable.audioSubject$.subscribe(audio => {
            if (audio) {
                this.lockInput();
                this.aiChatService.transcribe(audio).subscribe((text)=> {
                    if (/\w/.test(text) && text !== "you") {
                        this.unlockInput();
                        this.request(text);
                    }
                });
            }
        });
    }

    resetShowLoading() {
        if (this.showLoadingTimer) {
            clearTimeout(this.showLoadingTimer);
            this.showLoadingTimer = undefined;
        }
        this.showLoading = false;
        this.showLoadingTimer = setTimeout(()=>{
            this.showLoading = true;
            this.scrollToBottom();
            this.showLoadingTimer = undefined;
        },1000);
    }
    unsetShowLoading() {
        if (this.showLoadingTimer) {
            clearTimeout(this.showLoadingTimer);
            this.showLoadingTimer = undefined;
        }
        this.showLoading = false;
        this.scrollToBottom();
    }

    subscribeToAIChatObservable() {
        this.aiChatObservable.updates().subscribe(async (data: AIEvent) => {
            if (data.type == "progress") {
                this.status = data.data;
                if (data.data == null) {
                    //this.response = [];
                    this.unsetShowLoading();
                }
                this.scrollToBottom();
            }
            if (data.type == "text_stream") {
                this.response = data.data;
                this.resetShowLoading();
                this.scrollToBottom();
            }
        });
    }

    tableChats() {
        this.tablesService.getTableChats()
        .subscribe((_resChats: {chat:AIChatHistory,suggestions?:string[]}) => {
            this.conversation = _resChats.chat;
            if (!this.loading) {
                this.response = [];
            }
            this.suggestions = _resChats.suggestions || [];
            this.changeDetectorRef.detectChanges();
            this.scrollToBottom();
        })
    }

    async openSheet(sheetId:string) {
        await this.spreadsheetComponent.loadData(sheetId);
    }
    
    private manageRequest(query:string) {
        this.conversation.push({
            timestamp: Date.now(),
            detailType: 'user',
            correlationId: '',
            content: query,
            isAI: false,
            type: "user"
        });
        this.scrollToBottom();
        this.inputChat = '';
    }

    request(query:string) {
        this.showLoading = true;
        this.aiChatService.request(query).subscribe();
        this.manageRequest(query);
        this.scrollToBottom();
    }
    cancelOngoingRequest() {
        this.aiChatService.cancelRequest();
    }

    processInputChat(event?:KeyboardEvent){
        if (event) {
            if (event.key === 'Enter' && !event.shiftKey) {
                this.request(this.inputChat);
              }
        } else {
            this.request(this.inputChat);
        }
    }

    useSuggestion(txt:string) {
        this.request(txt);
    }

    lockInput() {
        this.loading = true;
    }
    unlockInput() {
        this.loading = false;
    }

    toggleChat() {
        this.visible = !this.visible;
    }

    ngOnDestroy(): void {
        // Any cleanup logic can go here
        this.loadingSubscription.unsubscribe();
    }


}