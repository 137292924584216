import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Config } from '../config/config';
import { Subject, BehaviorSubject } from 'rxjs';
import { AIChatHistory, UUID } from 'src/app/pages/aichat/aichat.model';
import { CreateSheetDto, SheetData } from 'src/app/pages/spreadsheet/spreadsheet.model';
import { HttPromises } from 'src/app/core/service/httPromises.service';
import { AIChat } from 'src/app/pages/aichat/aichat.component';


@Injectable({
    providedIn: 'root',
})
export class TablesService {
    private dataSubject = new Subject<string>();
    private notificationSubject = new Subject<string>();
    private tableChatsSubject = new BehaviorSubject<any>({chat:[],suggestions:[]});
    apiUrl = Config.backend_url + '/tables';
    constructor(
        private http: HttpClient,
        private httPromises: HttPromises
    ) { }

    sendData(data: any) {
        this.dataSubject.next(data);
    }

    getData() {
        return this.dataSubject.asObservable();
    }

    setUpdateNotification(data: any){
        this.notificationSubject.next(data);
    }

    getUpdateNotification(){
        return this.notificationSubject.asObservable();
    }

    setUpdateFromSpreadsheet(data: any){
        this.notificationSubject.next(data);
    }

    getUpdateFromSpreadsheet(){
        return this.notificationSubject.asObservable();
    }


    setTableChats(data:AIChatHistory,suggestions?:string[]){
        this.tableChatsSubject.next({chat:data,suggestions:suggestions||[]});
    }

    getTableChats(){
        return this.tableChatsSubject.asObservable();
    }

    getTablesByUser() {
        return this.http.get(`${this.apiUrl}/user`)
    }

    createTables(data: any) {
        return this.http.post(`${this.apiUrl}`, data)
    }

    createSheet(tableId:UUID) {
        return this.http.put(`${this.apiUrl}/${tableId}/createsheet`, {})
    }

    getTable(tableId: UUID) {
        return this.http.get(`${this.apiUrl}/tableId/${tableId}`)
    }

    getRawTableData(fileName: string, containerId?: UUID) {
        let uri = `${this.apiUrl}/rawfile/${fileName}`
        if (containerId) uri += `/${containerId}`;
        return this.http.get(uri);
    }

    getSignedURL(resourceKey: string) {
        let uri = `${this.apiUrl}/signedurl`;
        return this.http.get(uri, {
            params: { 'resource': resourceKey }
        });
    }

    getSelectedFields(sheetId: UUID, fields?: string[], unwindField?: string): Promise<unknown> {
        let params = new HttpParams();
        if (fields && fields.length) {
            fields.forEach((field) => {
                params = params.append('fields', field);
            });
        }
        if (unwindField) {
            params = params.set('unwind',unwindField);
        }
        return this.httPromises.get(`${this.apiUrl}/select/${sheetId}`,{params});
    }
    getSheetData(sheetId?: UUID, sheetOnly?:boolean): Promise<SheetData> {
        let params = new HttpParams();
        if (sheetOnly) {
            params = params.set('sheetOnly',true);
        }
        return this.httPromises.get(`${this.apiUrl}/sheet/${sheetId}`,{ params });
    }


    uploadFile(file: any, containerId?: UUID, tableId?: UUID, is_item_add: boolean = false) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.append('Accept', '*/*');
        let uri = `${this.apiUrl}/upload`;
        if (containerId) uri += `/${containerId}`;
        return this.http.post(uri, file)
    }

    uploadFileInDataspace(file: any, tableId: UUID) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.append('Accept', '*/*');
        let uri = `${this.apiUrl}/upload_file`;
        if (tableId) uri += `/${tableId}`;
        return this.http.post(uri, file)
    }

    appendFileInDataspace(file: any, tableId: UUID) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.append('Accept', '*/*');
        let uri = `${this.apiUrl}/append_file`;
        if (tableId) uri += `/${tableId}`;
        return this.http.post(uri, file)
    }

    updateTable(id: UUID, data: any) {
        return this.http.patch(`${this.apiUrl}/${id}`, data)
    }

    updateSheets(id: UUID, sheetId: UUID, data: any) {
        return this.http.put(`${this.apiUrl}/${id}/${sheetId}`, data)
    }

    updateXSheetTable(id: UUID, sheetId: UUID, data: any) {
        return this.http.put(`${this.apiUrl}/${id}/XSheets/${sheetId}`, data)
    }

    updateTableCell(tableId: UUID,
                    sheetId: UUID,
                    rowIndex: number,
                    columnIndex: number,
                    data:{
                        value: string|number, 
                        oldValue: string|number 
                    }) {
        return this.http.patch(`${this.apiUrl}/${tableId}/XSheets/${sheetId}/${rowIndex}/${columnIndex}`,data);
    }

    updateTableChats(id: UUID, data: any) {
        return this.http.put(`${this.apiUrl}/${id}/chats`, data)
    }

    uploadNewSheetFile(tableId: UUID, data: any) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.append('Accept', '*/*');
        return this.http.put(`${this.apiUrl}/${tableId}/sheet`, data)
    }

    uploadSheetFile(tableId: UUID, sheetId: UUID, data: any) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.append('Accept', '*/*');
        return this.http.put(`${this.apiUrl}/${tableId}/sheet/${sheetId}}`, data)
    }

    duplicateSheet(tableId: UUID, data: any) {
        return this.http.put(`${this.apiUrl}/${tableId}/duplicate_sheet`, data)
    }

    duplicateTable(tableId: UUID, data: any) {
        return this.http.put(`${this.apiUrl}/${tableId}/duplicate_table`, data)
    }

    deleteSheet(tableId: UUID, sheetId: UUID) {
        return this.http.delete(`${this.apiUrl}/${tableId}/delete/${sheetId}`)
    }

    deleteTable(tableId: UUID) {
        return this.http.delete(`${this.apiUrl}/${tableId}/delete`)
    }

    downloadXls(tableId: UUID) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/vnd.ms-excel'
            }),
            responseType: 'blob' as 'json'
          };
      
          return this.http.get(`${this.apiUrl}/${tableId}/downloadXls`, httpOptions)
    }

    downloadCsv(tableId: string, sheetId:string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'text/csv'
            }),
            responseType: 'blob' as 'json'
        };
      
        return this.http.get(`${this.apiUrl}/${tableId}/${sheetId}/downloadCsv`, httpOptions)
    }

    triggerDownload(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(url);
    }

    getTableByContainerId(id: UUID){
        return this.http.get(`${this.apiUrl}/container/${id}`)
    }

    getIntegrationsFromS3ToSpreadsheet(type:string){
        return this.http.get(`${this.apiUrl}/integrations/${type}`)
    }

}