import { Component, OnInit } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { StripeService } from './stripe.services';


@Component({
    selector: 'app-stripe',
    templateUrl: './stripe.component.html',
})
export class StripeComponent implements OnInit{
    productData:any;
    stripePromise: Promise<Stripe | null>;
    constructor(
        private stripeService: StripeService,
    ) { 
        this.stripePromise = loadStripe('pk_live_51O0MVzK09GQBKaWqyZG5BbyHlJMIyIR6JUcYGGIWRN91cGQmITZLWVArnJufXpWegOQR1QlDWfWOfqxPqXLC6VZP00ANqGOVbd');
    }
    ngOnInit(): void {
       
    }
    
    async checkout() {
        const stripe:any = await this.stripePromise;
        this.stripeService.redirectToCheckout(this.productData)
        .subscribe(async (response: any) => {
            console.log('response', response)
            await stripe.redirectToCheckout({
                sessionId: response.id,
            });
        })

    }

    async getProductById(){
        this.stripeService.getProductById('prod_PVk2t0IFUnSAcn')
        .subscribe((resObj:any) => {
            this.productData = resObj;
            console.log('resObj', resObj)
        })
    }

    async directRedirection(){
       //https://buy.stripe.com/test_5kA00D3Zv1NUdEI288
        // const paymentLink = 'https://buy.stripe.com/eVaeXA51f8bC32o3cf';
        const paymentLink = 'https://buy.stripe.com/test_7sIdRt53z1NU58cbIL';
        // https://buy.stripe.com/7sIcPsdxL9fG9qM5ko
        window.location.href = paymentLink;
    }
}