import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { Config } from 'src/app/core/config/config';

@Component({
    selector: 'hubspot-integration',
    templateUrl: './hubspot.component.html'
})

export class HubspotComponent implements OnInit {

    clientId: string = "";
    ClientSecret: string = "";

    credentials_title: string = "";
    access_token: string = "";

    code: string = '';
    loading: boolean = false;
    hubspotData: any;
    jobHistory:any;
    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService
    ) {
        
    }


    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.code = params['code'];
            console.log('code', params['code'])
            this.getToken()
        });
    }

    hubspotOauth() {
        // https://app-eu1.hubspot.com/oauth/authorize?client_id=5f3cbab8-b611-4065-8498-4c966d5b199d&redirect_uri=http://localhost:3000/auth/redirect&scope=contacts%20automation
        window.location.href = `https://app-eu1.hubspot.com/oauth/authorize?client_id=${this.clientId}&redirect_uri=${Config.backend_url}/auth/redirect&scope=contacts%20automation`;
    }

    getToken(){
        this.airbyteService.getHubspotToken(this.code)
        .subscribe((res:any) => {
            console.log('res', res)
        })
    }

    createHubspot(){
        const { credentials_title, access_token } = this;
        this.airbyteService.createHubspot({ credentials_title, access_token }).subscribe()
    }

}
