import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpreadSheetComponent } from './spreadsheet/spreadsheet.component';
import { GoogleAnalyticsComponent } from '../integration/analytics/analytics.component';
import { IntegrationComponent } from '../integration/integration.component';
import { AdsComponent } from '../integration/ads/ads.component';
import { ShopifyComponent } from '../integration/shopify/shopify.component';
import { WooComComponent } from '../integration/woocom/woocom.component';
import { HubspotComponent } from '../integration/hubspot/hubspot.component';
import { JobHistoryComponent } from '../integration/job-history/job-history.component';
import { DriveComponent } from '../integration/drive/drive.component';

const routes: Routes = [
  {
    path: '',
    component: SpreadSheetComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PagesRoutingModule {
}
