<header>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="d-flex">
            <div class="sidebar-toggle" (click)="toggleSidebar()"  placement="bottom"> 
                <img src="../../../assets/img/icons/chevron.png">
            </div>
            <!--<div class="sidebar-toggle-mini" (click)="toggleSidebar()">
                <img src="../../../assets/img/icons/chevron.png">
            </div>-->
            <div class="dropdown" ngbDropdown >
            <button class="btn dropdown-toggle sheet_dd" type="button" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle *ngIf="dataset">
                <span *ngIf="!dataset.isSheetNameEdit" >{{dataset.tableName || 'Untitled Dataspace'}}</span>
                <input *ngIf="dataset.isSheetNameEdit" [(ngModel)]="dataset.editedSheetName" (blur)="stopEditing(dataset)" (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()" />
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" (click)="editTableName(dataset)"><i class="bi bi-pencil"></i>Rename table</a>
                <a class="dropdown-item" (click)="duplicateTable(dataset)"><i class="bi bi-copy"></i>Duplicate table</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteRecordModal"
                    (click)="confirm(deleteModel,dataset.tableId)"><i class="bi bi-trash"></i>Delete table</a>
            </div>
        </div>    
        </div>


        <div class="collapse navbar-collapse justify_end" id="navbarSupportedContent">
            <div class="share-dataset" data-bs-toggle="modal" data-bs-target="#openShareModal"
            (click)="openModal(openShareModal)" placement="bottom" ngbTooltip="Share Dataset">
                <img src="../../../assets/img/icons/users.png">
                Share
            </div>
            <div class="help" placement="bottom" ngbTooltip="Help" ngbDropdown>
                <img src="../../../assets/img/icons/help.png" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <h6 class="dropdown-header">HELP</h6>
                    <div class="dropdown-divider"></div>
                </div>
            </div>
            <!-- <div>
                <i class="bi bi-moon"></i>
            </div> -->
            <div class="dropdown header-item notifications" ngbDropdown placement="bottom" ngbTooltip="Notifications">
                    <img src="../../../assets/img/icons/bell.png" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <span class="position-absolute topbar-badge fs-10 badge">3</span>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <div class="row align-items-center">
                        <div class="col">
                            <a class="navbar-brand" href="#">Notifications</a>
                        </div>
                        <div class="col-auto">
                            <span class="badge bg-light-subtle text-body"> 4 New</span>
                        </div>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container-fluid">
                          <ul class="navbar-nav">
                            <li class="nav-item">
                              <a class="nav-link" (click)="setActiveTab('all')" [class.active]="activeTab === 'all'">All</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" (click)="setActiveTab('messages')" [class.active]="activeTab === 'messages'">Messages</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" (click)="setActiveTab('alerts')" [class.active]="activeTab === 'alerts'">Alerts</a>
                            </li>
                          </ul>
                        </div>
                      </nav>
                      
                      <div class="container mt-4">
                        <div *ngIf="activeTab === 'all'">
                          <h3>All</h3>
                          <p>Displaying all notifications.</p>
                        </div>
                        <div *ngIf="activeTab === 'messages'">
                          <h3>Messages</h3>
                          <p>Displaying messages.</p>
                        </div>
                        <div *ngIf="activeTab === 'alerts'">
                          <h3>Alerts</h3>
                          <p>Displaying alerts.</p>
                        </div>
                      </div>
                </div>
            </div>
            <div class="dropdown header-item topbar-user" ngbDropdown placement="right" ngbTooltip="User Menu">
                <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <div class="d-flex align-items-center">
                        {{getUserInitial()}}
                    </div>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- item-->
                    <!-- IF ACCOUNT HAS ORGANISATION, OTHERWISE HIDE THIS H6 --><h6 class="dropdown-header">Organisation</h6>
                    <h6 class="dropdown-header">{{cognitoService.getLoggedInUser()?.name}}</h6>
                    <!-- MAIL ADDRESS OF USER --><h6 class="dropdown-header">Mail address</h6>
                    <a class="dropdown-item" routerLink="/profile" (click)="hideSideBarChevron = true">
                        <img src="../../../assets/img/icons/users.png">
                        <span class="align-middle">Account</span></a>
                    <hr>
                    <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#contactSalesModal" (click)="openModal(contactSalesModal)">
                        <img src="../../../assets/img/icons/letter.png">
                        <span class="align-middle">Contact Sales</span>
                    </a>
                    <a class="dropdown-item" href="https://talonic.ai/pricing" target="_blank">
                        <img src="../../../assets/img/icons/premium.png">
                        <span class="align-middle">Upgrade</span>
                    </a>
                    <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#tellaFrndModal" (click)="openModal(tellaFrndModal)">
                        <img src="../../../assets/img/icons/friend.png">
                        <span class="align-middle">Tell a friend</span>
                    </a>
                    <hr>
                    <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#integrationModal" (click)="openModal(integrationModal)">
                        <img src="../../../assets/img/icons/link.png">
                        <span class="align-middle">Integrations</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="logout()">
                        <img src="../../../assets/img/icons/logout.png">
                        <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                        <span class="align-middle" data-key="t-logout" >Logout</span>
                    </a>
                </div>
            </div>
            <div class="credit-count">
                <img src="../../../assets/img/icons/infinity.png">
                <div class="hover-tooltip">
                    This number shows your remaining credits for Talonic AI operations.
                </div>
            </div>

            <!-- Account Menu in Sidebar -->

        <div class="dropdown header-item sidebar-user" ngbDropdown placement="right" ngbTooltip="User Menu">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                <div class="d-flex align-items-center">
                    {{getUserInitial()}}    
                </div>
            </button>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <!-- item-->
                <!-- IF ACCOUNT HAS ORGANISATION, OTHERWISE HIDE THIS H6 --><h6 class="dropdown-header">Organisation</h6>
                <h6 class="dropdown-header">{{cognitoService.getLoggedInUser()?.name}}</h6>
                <!-- MAIL ADDRESS OF USER --><h6 class="dropdown-header">{{cognitoService.getLoggedInUser()?.email}}</h6>
                <a class="dropdown-item" routerLink="/profile" (click)="hideSideBarChevron = true">
                    <img src="../../../assets/img/icons/users.png">
                    <span class="align-middle">Account</span></a>
                <hr>
                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#contactSalesModal" (click)="openModal(contactSalesModal)">
                    <img src="../../../assets/img/icons/letter.png">
                    <span class="align-middle">Contact Sales</span>
                </a>
                <a class="dropdown-item" href="https://talonic.ai/pricing" target="_blank">
                    <img src="../../../assets/img/icons/premium.png">
                    <span class="align-middle">Upgrade</span>
                </a>
                <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#tellaFrndModal" (click)="openModal(tellaFrndModal)">
                    <img src="../../../assets/img/icons/friend.png">
                    <span class="align-middle">Tell a friend</span>
                </a>
                <hr>
                <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#integrationModal" (click)="openModal(integrationModal)">
                    <img src="../../../assets/img/icons/link.png">
                    <span class="align-middle">Integrations</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">
                    <img src="../../../assets/img/icons/logout.png">
                    <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                    <span class="align-middle" data-key="t-logout" >Logout</span>
                </a>
            </div>
        </div>
            
        </div>
    </nav>
</header>
<!-- removeItemModal -->
<ng-template #deleteModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you Sure You want to Remove this table?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="deleteItem(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- share Modal -->
<ng-template #openShareModal let-modal>
    <div class="modal-content share-dateset">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2">
                <div class="">
                    <h4>Share (Name of Dataset)</h4>
                    <div class="row">
                        <div class="col">
                            <label>Link to share</label>
                            <div>https://talonic.ai/share/123456789</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>People with link can</label>
                            <select class="form-select">
                                <option>Edit</option>
                                <option>Duplicate</option>
                                <option>Read</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')">
                    <i class="ri-close-line me-1 align-middle"></i> Close
                </button>
                <button type="button" class="btn w-sm btn-danger" id="delete-product" (click)="deleteItem(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>


<!-- contactSalesModal -->
<ng-template #contactSalesModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Contact Sales Modal</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="openModal(contactSalesModal)"
                    (click)="modal.close('Close click')">Yes</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- tellaFrndModal -->
<ng-template #tellaFrndModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Tell A Friend Modal</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="openModal(tellaFrndModal)"
                    (click)="modal.close('Close click')">Yes</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- integrationModal -->
<ng-template #integrationModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Integration Modal</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="openModal(integrationModal)"
                    (click)="modal.close('Close click')">Yes</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>