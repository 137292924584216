<div class="profile-container">
  <header class="profile-header">
    <div class="header-content">
      <h1>Account Overview</h1>
      <div class="breadcrumb">
        <button type="button" class="btn btn-link" routerLink="/">
          <img src="../../../assets/img/icons/chevron.png" alt="Back" />
          <span>Home</span>
        </button>
        <span class="separator">/</span>
        <span class="current-page">Account Overview</span>
      </div>
    </div>
  </header>

  <div class="profile-content-scroll">
    <div class="profile-content p-4 col-md-12">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <!-- First Row: User Info and Subscription -->
          <div class="profile-section user-info section col-md-6 pr-3">
            <div class="user-avatar">
              <img 
                [src]="userAvatarUrl || '../../../assets/img/user_dummy.jpg'" 
                alt="User Avatar" 
                (click)="triggerFileInput()"
                style="cursor: pointer;"
              >
              <input 
                type="file" 
                id="avatar-upload" 
                (change)="onAvatarChange($event)" 
                accept="image/*" 
                style="display: none;" 
                #fileInput 
              >
              <span class="user-initial" *ngIf="!userAvatarUrl">{{getUserInitial()}}</span>
            </div>
            <div class="user-details">
              <div class="form-group">
                <label for="name">Name</label>
                <div class="input-group">
                  <input type="text" id="name" formControlName="name" class="form-control">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary" (click)="saveField()">
                      <i class="bi bi-check"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" formControlName="email" class="form-control" readonly>            
              </div>
            </div>
          </div>
  
          <div class="profile-section subscription section col-md-6 ml-10 row">
            <div class="col-md-6">
              <h2>Subscription</h2>
              <div class="subscription-info">
                <div class="plan-info">
                  <h3>{{userSubscriptionTier}} Plan</h3>
                  <!-- <a href="https://www.talonic.ai/pricing" class="btn btn-outline-primary">Change Plan</a> -->
                </div>
              </div>
            </div>
            
            <div class="col-md-6">
              <h2>Credits</h2>
              <div class="credits-info">
                <p>{{user.remainingCredits || 0}} / {{user.credits}} Used</p>
                <a (click)="purchaseCredits()" class="btn btn-outline-primary">Buy Credits</a>
              </div>
            </div>
          </div>          
        </div>
  
        <div class="row mt-4">
          <!-- Second Row: Organization and Billing Address -->
          <div class="profile-section organization section col-md-6 pr-3">
            <h2>Organization</h2>
            <div class="organization-info">
              <div class="form-group">
                <label for="organisation-name">Organization Name</label>
                <div class="input-group">
                  <input type="text" id="organisation-name" formControlName="organization" class="form-control">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary" (click)="saveField()">
                      <i class="bi bi-check"></i>
                    </button>
                  </div>
                </div>
              </div>
              <a href="#">Invite Members</a>
            </div>
          </div>
  
          <div class="profile-section billing-address section col-md-6 ml-10">
            <h3>Billing Address</h3>
            <div class="billing-info mt-5">
              <div *ngIf="!isEditingBillingAddress">
                <address>
                  Address: {{userForm.get('address')?.value || ''}}<br>
                  Zipcode: {{userForm.get('zipcode')?.value || ''}}, City: {{userForm.get('city')?.value || ''}}<br>
                  Country: {{userForm.get('country')?.value || ''}}<br>
                </address>
                <a class="btn btn-link" (click)="toggleBillingAddressEdit()">Change billing address</a>
              </div>
              <div *ngIf="isEditingBillingAddress">
                <div class="form-group">
                  <label for="address">Address</label>
                  <div class="input-group">
                    <input type="text" id="address" formControlName="address" class="form-control">
                  </div>
                </div>
                <div class="form-group">
                  <label for="zipcode">Zip Code</label>
                  <div class="input-group">
                    <input type="text" id="zipcode" formControlName="zipcode" class="form-control">
                  </div>
                </div>
                <div class="form-group">
                  <label for="city">City</label>
                  <div class="input-group">
                    <input type="text" id="city" formControlName="city" class="form-control">
                  </div>
                </div>
                <div class="form-group">
                  <label for="country">Country</label>
                  <div class="input-group">
                    <input type="text" id="country" formControlName="country" class="form-control">
                  </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="saveField()">Save Billing Address</button>
                <button type="button" class="btn btn-link" (click)="toggleBillingAddressEdit()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  
</div>