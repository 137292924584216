import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../config/config';
import { IShopifyPayload } from 'src/app/pages/integration/shopify/shopify';


@Injectable({
  providedIn: 'root',
})
export class AirByteService {
  constructor(private http: HttpClient) {}

  getAirbyteData(type:string){
    return this.http.get(`${Config.backend_url}/airbyte/${type}`)
  }
 
  createAnalytics(propertyId: any){
    return this.http.post(`${Config.backend_url}/analytics`, {propertyId});
  }

  getDailyActiveUsers(property_id:string){
    return this.http.get(`${Config.backend_url}/analytics/daily_users/${property_id}`)
  }

  getDevices(property_id:string){
    return this.http.get(`${Config.backend_url}/analytics/devices/${property_id}`)
  }

  getJobHistory(id:string, type:string){
    return this.http.get(`${Config.backend_url}/jobs/history/${id}/${type}`)
  }
  syncJobStatus(jobId:number){
    return this.http.get(`${Config.backend_url}/jobs/sync/${jobId}`)
  }
  syncJobs(){
    return this.http.get(`${Config.backend_url}/jobs/sync`)
  }

  syncDataToS3(property_id: string){
    return this.http.get(`${Config.backend_url}/analytics/syncS3/${property_id}`)
  }

  createShopify(payload:IShopifyPayload){
    return this.http.post(`${Config.backend_url}/shopify`, payload)
  }

  createWoocom(payload:IShopifyPayload){
    return this.http.post(`${Config.backend_url}/woocom`, payload)
  }

  getHubspotToken(code:string){
    return this.http.get(`${Config.backend_url}/hubspot/redirect/${code}`)
  }

  createHubspot(payload: any){
    return this.http.post(`${Config.backend_url}/hubspot`, payload)
  }

}
