<div>
    <div class="">
        <div id="content" class="row">

            <!-- BG VIDEO -->
            <!--<div class="bg_video">
                <video autoplay loop muted>   
                    <source src="../../../assets/img/talonic_bg.mp4" type="video/mp4" />
                 </video>
             </div>-->

            <!-- SIDEBAR -->
            <div class="sidebar col-lg-2 col-md-2 col-sm-4" fileDragDrop [allowedMimeTypes]="valid_MIME_types"
                [allowedExtensions]="valid_extensions" (filesChangeEmitter)="onItemDrop($event,true)" #fileUpload>
                <div class="new-workspace" (click)="toggleSidebarItemCollapse()">
                    <div class="logo">
                        <img src="../../../assets/img/vhi_logo.png" />
                        <img src="../../../assets/img/icons/talonic_icon.png" />
                    </div>
                </div>
                <div class="dragover-data">
                    <lord-icon src="../../../assets/img/icons/drop.json" trigger="loop"></lord-icon>
                    <p>
                        Drop your data<br>
                    </p>
                </div>
                <div class="sidebar-wrapper">
                    <div class="new-sidebar-item" (click)="newDataset()" (mouseenter)="showTooltip()"
                        (mouseleave)="hideTooltip()" placement="bottom">
                        <a id="new-sheet">
                            <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="loading"></span>
                        </a>
                        <!--<app-tooltip *ngIf="currentTooltipOrder !== null" [content]="tooltips[currentTooltipOrder].content" [order]="currentTooltipOrder" 
                            (closeTooltip)="closeTooltip()" (nextTooltip)="nextTooltip()" (previousTooltip)="previousTooltip()">
                        </app-tooltip>
                        <a id="new-folder" (click)="newContainer()"  placement="bottom" ngbTooltip="New folder">
                            <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="folderLoading"></span>
                        </a>-->
                    </div>
                    <div class="index-item workspaces" (click)="toggleSidebarItemCollapse('workspaces')">
                        <lord-icon src="../../../assets/img/icons/sheets.json" trigger="hover"></lord-icon>
                        <label>Workspaces</label>
                        <!--<div class="new-sidebar-item">
                            <a id="new-sheet" (click)="newDataset()" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()"  placement="bottom" ngbTooltip="New dataset">
                                <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>    
                            </a>
                            <app-tooltip *ngIf="currentTooltipOrder !== null" [content]="tooltips[currentTooltipOrder].content" [order]="currentTooltipOrder" 
                                (closeTooltip)="closeTooltip()" (nextTooltip)="nextTooltip()" (previousTooltip)="previousTooltip()">
                            </app-tooltip>
                            <a id="new-folder" (click)="newContainer()"  placement="bottom" ngbTooltip="New folder">
                                <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="folderLoading"></span>
                            </a>
                        </div>-->
                    </div>
                    <div class="index-wrapper workspaces-mini-index">
                        <section *ngFor="let _table of getWorkspaces(4); let index = index " [draggable]="true"
                            (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
                            <a [ngClass]="{ 'active': _table.tableId == activeTable }" *ngIf="!_table.containerId">
                                <div class="table-item" (click)="getSpreadSheet(_table);">
                                    <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                    <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                        (keydown.enter)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                        (click)="$event.stopPropagation()" />
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="_table.loading"></span>
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <div
                                            (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                            <i class="bi bi-pencil"></i> Rename
                                        </div>
                                        <div (click)="duplicateTable(_table)">
                                            <i class="bi bi-copy"></i> Duplicate
                                        </div>
                                        <div (click)="confirm(deleteTableModel,_table.tableId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteTableModel"></i> Delete
                                        </div>
                                        <!--<div  (click)="openGear(openDatasetGearModal, _table.tableId)">
                                            <i class="bi bi-gear" data-bs-toggle="modal" data-bs-target="#openDatasetGearModal"></i>  Settings
                                        </div>-->
                                    </span>
                                </span>
                            </a>
                        </section>
                        <section *ngFor="let _container of containers">
                            <div class="folder" *ngIf="!_container.isContainerNameEdit">
                                <a class="folder" (dblclick)="doubleClickEditContainer(_container)">
                                    <lord-icon src="../../../assets/img/icons/folder.json"
                                        trigger="hover"></lord-icon>{{_container.containerName}}
                                </a>
                                <span (click)="toggleAccordion(_container)">
                                    <i *ngIf="_container.toogleChevron" class="bi bi-chevron-down"></i>
                                    <i *ngIf="!_container.toogleChevron" class="bi bi-chevron-up"></i>
                                </span>

                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                        <div (click)="newDataset(_container.containerId)">
                                            <i class="bi bi-plus"></i> New Dataset
                                        </div>
                                        <div (click)="confirm(deleteContainerModel,_container.containerId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteRecordModal"></i> Delete
                                        </div>
                                        <div (click)="openGear(openFolderGearModal,_container.containerId)">
                                            <i class="bi bi-gear" data-bs-toggle="modal"
                                                data-bs-target="#openFolderGearModal"></i> Settings
                                        </div>
                                    </span>
                                </span>
                            </div>
                            <span *ngIf="_container.isContainerNameEdit">
                                <a class="folder">
                                    <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                                    <input [(ngModel)]="_container.editedContainerName"
                                        (focusout)="stopEditContainer(_container)" />
                                </a>
                            </span>
                            <section *ngIf="!_container.toogleChevron">
                                <a class="in-folder" *ngFor="let _table of _container.tables ; let index = index "
                                    [draggable]="true" (dragstart)="onDragStart(index, _container.containerId)"
                                    (dragenter)="onDragEnter(index, _container.containerId)" (dragend)="onDragEnd()"
                                    [ngClass]="{ 'active': _table.tableId == activeTable }">
                                    <!--<lord-icon src="../../../assets/img/icons/sheet.json" trigger="hover"></lord-icon>-->
                                    <div class="table-item" (click)="getSpreadSheet(_table);">
                                        <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                        <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                            (blur)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                            (click)="$event.stopPropagation()" />
                                    </div>
                                    <span ngbDropdown>
                                        <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                                class="bi bi-three-dots"></i></button>
                                        <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                            <div
                                                (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                                <i class="bi bi-pencil"></i> Rename
                                            </div>
                                            <div (click)="duplicateTable(_table)">
                                                <i class="bi bi-copy"></i> Duplicate
                                            </div>
                                            <div (click)="confirm(deleteTableModel,_table.tableId)">
                                                <i class="bi bi-trash" data-bs-toggle="modal"
                                                    data-bs-target="#deleteTableModel"></i> Delete
                                            </div>
                                            <div (click)="openGear(openDatasetGearModal, _table.tableId)">
                                                <i class="bi bi-gear" data-bs-toggle="modal"
                                                    data-bs-target="#openDatasetGearModal"></i> Settings
                                            </div>
                                        </span>
                                    </span>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="_table.loading"></span>
                                </a>
                            </section>
                        </section>
                    </div>
                    <div class="index-item dataspace" (click)="toggleSidebarItemCollapse('dataspace')">
                        <lord-icon src="../../../assets/img/icons/data.json" trigger="hover"></lord-icon>
                        <label>Data</label>
                        <!--<div class="new-sidebar-item">
                           <a id="new-sheet" (click)="newDataset()" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()"  placement="bottom" ngbTooltip="New dataset">
                                <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>    
                            </a>
                            <app-tooltip *ngIf="currentTooltipOrder !== null" [content]="tooltips[currentTooltipOrder].content" [order]="currentTooltipOrder" 
                                (closeTooltip)="closeTooltip()" (nextTooltip)="nextTooltip()" (previousTooltip)="previousTooltip()">
                            </app-tooltip>
                            <a id="new-folder" (click)="newContainer()"  placement="bottom" ngbTooltip="New folder">
                                <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="folderLoading"></span>
                            </a>
                        </div>-->
                    </div>
                    <div class="index-wrapper dataspace-mini-index">
                        <section *ngIf="uploadFileLoading" class="uploading">
                            <a>
                                <lord-icon class="data-source-type-icon loading"
                                    src="../../../assets/img/icons/circle.json" trigger="loop"></lord-icon>
                                <div class="table-item">
                                    <div>{{fileName}}</div>
                                </div>
                            </a>
                        </section>
                        <section *ngFor="let _table of getDataspaces(4); let index = index " [draggable]="true"
                            (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
                            <a [ngClass]="{ 'active': _table.tableId == activeTable }" *ngIf="!_table.containerId">
                                <div class="data-source-type-icon" [ngClass]="_table.file?.extension"></div>
                                <div class="table-item" (click)="getSpreadSheet(_table);">
                                    <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                    <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                        (keydown.enter)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                        (click)="$event.stopPropagation()" />
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="_table.loading"></span>
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <!-- <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i class="bi bi-three-dots" placement="top" ngbTooltip="More"></i></button> -->
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <div
                                            (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                            <i class="bi bi-pencil"></i> Rename
                                        </div>
                                        <div (click)="duplicateTable(_table)">
                                            <i class="bi bi-copy"></i> Duplicate
                                        </div>
                                        <div (click)="confirm(deleteTableModel,_table.tableId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteTableModel"></i> Delete
                                        </div>
                                        <!--<div  (click)="openGear(openDatasetGearModal, _table.tableId)">
                                            <i class="bi bi-gear" data-bs-toggle="modal" data-bs-target="#openDatasetGearModal"></i>  Settings
                                        </div>-->
                                    </span>
                                </span>
                            </a>
                        </section>
                        <!--
                        <section *ngFor="let file of uploads; let index = index">
                            <a [ngClass]="{ 'active': file.key == activeUpload }">
                                <div class="data-source-type-icon excel"></div>
                                <div class="table-item" (click)="fileOpen(file);">
                                    <div  *ngIf="!file.isNameEdit">{{file.baseName}}</div>
                                    <input *ngIf="file.isNameEdit" [(ngModel)]="file.editedName" (keydown.enter)="fileRename(file)" (keydown.space)="$event.stopPropagation()"
                                    (click)="$event.stopPropagation()" />
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="file.loading"></span>
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <div (click)="file.isNameEdit = !file.isNameEdit;file.editedName = file.baseName">
                                            <i class="bi bi-pencil" ></i> Rename
                                        </div>
                                        <div (click)="confirm(deleteFileModel,file.key)">
                                            <i class="bi bi-trash" data-bs-toggle="modal" data-bs-target="#deleteTableModel"></i>  Delete
                                        </div>
                                    </span>
                                </span>
                            </a>
                        </section>
                        -->
                    </div>
                    <div class="index-item integrations" (click)="toggleSidebarItemCollapse('integrations')">
                        <lord-icon src="../../../assets/img/icons/integrations.json" trigger="hover"></lord-icon>
                        <label>Integrations</label>
                        <div class="new-sidebar-item">

                        </div>
                    </div>
                    <!--<a id="add-integration" data-bs-toggle="modal" data-bs-target="#openAddIntegrationModal"
                    (click)="openModal(openAddIntegrationModal)">
                        <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>Add integration
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                    </a>-->
                    <div class="integrations-list">
                        <a click="getIntegrationData()">
                            <img src="../../../assets/img/integration-logos/google-analytics2.png"
                                alt="Google Analytics" />
                            <span class="flex-grow-1">Google Analytics</span>
                            <span class="gear-icon">
                                <i class="bi bi-gear" click="redirectionToIntegrations('/analytics')"></i>
                            </span>
                        </a>
                        <a>
                            <img src="../../../assets/img/integration-logos/google-ads.png" alt="Google Ads" />
                            <span class="flex-grow-1">Google Ads</span>
                            <span class="gear-icon">
                                <i class="bi bi-gear" click="redirectionToIntegrations('/ads')"></i>
                            </span>
                        </a>
                        <a>
                            <img src="../../../assets/img/integration-logos/facebook.png" alt="Facebook Ads" />
                            <span class="flex-grow-1">Facebook Ads</span>
                            <span class="gear-icon">
                                <i class="bi bi-gear" click="redirectionToIntegrations('/facebook')"></i>
                            </span>
                        </a>
                    </div>



                    <!-- FILE IMPORT UI -->
                    <label class="drop-import" placement="bottom">
                        <input type="file" class="file-input" (change)="onItemDrop($event)" #fileUpload
                            [accept]="valid_files">
                        <lord-icon src="../../../assets/img/icons/cloud-upload.json" trigger="hover"></lord-icon>
                        <span *ngIf="!fileName">
                            Click here or drop<br>files to upload
                        </span>
                        <span *ngIf="fileName">{{fileName}}</span>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="uploadFileLoading"></span>
                    </label>

                </div>
            </div>
            <div class="detail-sidebar workspaces-sidebar">
                <div class="index-item workspaces" (click)="toggleSidebarItemCollapse('workspaces')">
                    <lord-icon src="../../../assets/img/icons/workspace.json" trigger="hover"></lord-icon>
                    <label>Workspaces</label>
                </div>
                <div class="new-workspace" (click)="newDataset()" (mouseenter)="showTooltip()"
                    (mouseleave)="hideTooltip()" placement="bottom">
                    <img src="../../../assets/img/icons/talonic_icon.png" />
                    <span>New Workspace</span>
                    <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                </div>
                <div class="index-wrapper">
                    <section *ngFor="let _table of getWorkspaces(); let index = index " [draggable]="true"
                        (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
                        <a [ngClass]="{ 'active': _table.tableId == activeTable }" *ngIf="!_table.containerId">
                            <div class="table-item" (click)="getSpreadSheet(_table);">
                                <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                    (keydown.enter)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                    (click)="$event.stopPropagation()" />
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="_table.loading"></span>
                            </div>
                            <span ngbDropdown>
                                <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i
                                        class="bi bi-three-dots"></i></button>
                                <!-- <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i class="bi bi-three-dots" placement="top" ngbTooltip="More"></i></button> -->
                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <div
                                        (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                        <i class="bi bi-pencil"></i> Rename
                                    </div>
                                    <div (click)="duplicateTable(_table)">
                                        <i class="bi bi-copy"></i> Duplicate
                                    </div>
                                    <div (click)="confirm(deleteTableModel,_table.tableId)">
                                        <i class="bi bi-trash" data-bs-toggle="modal"
                                            data-bs-target="#deleteTableModel"></i> Delete
                                    </div>
                                    <!--<div  (click)="openGear(openDatasetGearModal, _table.tableId)">
                                        <i class="bi bi-gear" data-bs-toggle="modal" data-bs-target="#openDatasetGearModal"></i>  Settings
                                    </div>-->
                                </span>
                            </span>
                        </a>
                    </section>
                    <section *ngFor="let _container of containers">
                        <div class="folder" *ngIf="!_container.isContainerNameEdit">
                            <a class="folder" (dblclick)="doubleClickEditContainer(_container)">
                                <lord-icon src="../../../assets/img/icons/folder.json"
                                    trigger="hover"></lord-icon>{{_container.containerName}}
                            </a>
                            <span (click)="toggleAccordion(_container)">
                                <i *ngIf="_container.toogleChevron" class="bi bi-chevron-down"></i>
                                <i *ngIf="!_container.toogleChevron" class="bi bi-chevron-up"></i>
                            </span>

                            <span ngbDropdown>
                                <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                        class="bi bi-three-dots"></i></button>
                                <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                    <div (click)="newDataset(_container.containerId)">
                                        <i class="bi bi-plus"></i> New Dataset
                                    </div>
                                    <div (click)="confirm(deleteContainerModel,_container.containerId)">
                                        <i class="bi bi-trash" data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"></i> Delete
                                    </div>
                                    <div (click)="openGear(openFolderGearModal,_container.containerId)">
                                        <i class="bi bi-gear" data-bs-toggle="modal"
                                            data-bs-target="#openFolderGearModal"></i> Settings
                                    </div>
                                </span>
                            </span>
                        </div>
                        <span *ngIf="_container.isContainerNameEdit">
                            <a class="folder">
                                <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                                <input [(ngModel)]="_container.editedContainerName"
                                    (focusout)="stopEditContainer(_container)" />
                            </a>
                        </span>
                        <section *ngIf="!_container.toogleChevron">
                            <a class="in-folder" *ngFor="let _table of _container.tables ; let index = index "
                                [draggable]="true" (dragstart)="onDragStart(index, _container.containerId)"
                                (dragenter)="onDragEnter(index, _container.containerId)" (dragend)="onDragEnd()"
                                [ngClass]="{ 'active': _table.tableId == activeTable }">
                                <!--<lord-icon src="../../../assets/img/icons/sheet.json" trigger="hover"></lord-icon>-->
                                <div class="table-item" (click)="getSpreadSheet(_table);">
                                    <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                    <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                        (blur)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                        (click)="$event.stopPropagation()" />
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                        <div
                                            (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                            <i class="bi bi-pencil"></i> Rename
                                        </div>
                                        <div (click)="duplicateTable(_table)">
                                            <i class="bi bi-copy"></i> Duplicate
                                        </div>
                                        <div (click)="confirm(deleteTableModel,_table.tableId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteTableModel"></i> Delete
                                        </div>
                                        <div (click)="openGear(openDatasetGearModal, _table.tableId)">
                                            <i class="bi bi-gear" data-bs-toggle="modal"
                                                data-bs-target="#openDatasetGearModal"></i> Settings
                                        </div>
                                    </span>
                                </span>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="_table.loading"></span>
                            </a>
                        </section>
                    </section>
                </div>
            </div>

            <div class="detail-sidebar dataspace-sidebar">
                <div class="index-item dataspace" (click)="toggleSidebarItemCollapse('dataspace')">
                    <lord-icon src="../../../assets/img/icons/data.json" trigger="hover"></lord-icon>
                    <label>Data</label>
                    <!--<a id="new-sheet" (click)="newDataset()" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()"  placement="bottom" ngbTooltip="New dataset">
                            <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>    
                        </a>-->
                </div>
                <div class="new-workspace" (click)="newContainer()" placement="bottom">
                    <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                    <span>New Folder</span>
                    <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                </div>
                <div class="index-wrapper dataspace-index">
                    <section *ngFor="let _table of getDataspaces(); let index = index " [draggable]="true"
                        (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
                        <a [ngClass]="{ 'active': _table.tableId == activeTable }" *ngIf="!_table.containerId">
                            <div class="data-source-type-icon" [ngClass]="_table.file?.extension"></div>
                            <div class="table-item" (click)="getSpreadSheet(_table);">
                                <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                    (keydown.enter)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                    (click)="$event.stopPropagation()" />
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="_table.loading"></span>
                            </div>
                            <span ngbDropdown>
                                <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i
                                        class="bi bi-three-dots"></i></button>
                                <!-- <button class="btn" id="dropdownBasic1" ngbDropdownToggle><i class="bi bi-three-dots" placement="top" ngbTooltip="More"></i></button> -->
                                <span ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <div
                                        (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                        <i class="bi bi-pencil"></i> Rename
                                    </div>
                                    <div (click)="duplicateTable(_table)">
                                        <i class="bi bi-copy"></i> Duplicate
                                    </div>
                                    <div (click)="confirm(deleteTableModel,_table.tableId)">
                                        <i class="bi bi-trash" data-bs-toggle="modal"
                                            data-bs-target="#deleteTableModel"></i> Delete
                                    </div>
                                    <!--<div  (click)="openGear(openDatasetGearModal, _table.tableId)">
                                        <i class="bi bi-gear" data-bs-toggle="modal" data-bs-target="#openDatasetGearModal"></i>  Settings
                                    </div>-->
                                </span>
                            </span>
                        </a>
                    </section>
                    <section *ngFor="let _container of containers">
                        <div class="folder" *ngIf="!_container.isContainerNameEdit">
                            <a class="folder" (dblclick)="doubleClickEditContainer(_container)">
                                <lord-icon src="../../../assets/img/icons/folder.json"
                                    trigger="hover"></lord-icon>{{_container.containerName}}
                            </a>
                            <span (click)="toggleAccordion(_container)">
                                <i *ngIf="_container.toogleChevron" class="bi bi-chevron-down"></i>
                                <i *ngIf="!_container.toogleChevron" class="bi bi-chevron-up"></i>
                            </span>

                            <span ngbDropdown>
                                <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                        class="bi bi-three-dots"></i></button>
                                <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                    <div (click)="newDataset(_container.containerId)">
                                        <i class="bi bi-plus"></i> New Dataset
                                    </div>
                                    <div (click)="confirm(deleteContainerModel,_container.containerId)">
                                        <i class="bi bi-trash" data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"></i> Delete
                                    </div>
                                    <div (click)="openGear(openFolderGearModal,_container.containerId)">
                                        <i class="bi bi-gear" data-bs-toggle="modal"
                                            data-bs-target="#openFolderGearModal"></i> Settings
                                    </div>
                                </span>
                            </span>
                        </div>
                        <span *ngIf="_container.isContainerNameEdit">
                            <a class="folder">
                                <lord-icon src="../../../assets/img/icons/folder.json" trigger="hover"></lord-icon>
                                <input [(ngModel)]="_container.editedContainerName"
                                    (focusout)="stopEditContainer(_container)" />
                            </a>
                        </span>
                        <section *ngIf="!_container.toogleChevron">
                            <a class="in-folder" *ngFor="let _table of _container.tables ; let index = index "
                                [draggable]="true" (dragstart)="onDragStart(index, _container.containerId)"
                                (dragenter)="onDragEnter(index, _container.containerId)" (dragend)="onDragEnd()"
                                [ngClass]="{ 'active': _table.tableId == activeTable }">
                                <!--<lord-icon src="../../../assets/img/icons/sheet.json" trigger="hover"></lord-icon>-->
                                <div class="table-item" (click)="getSpreadSheet(_table);">
                                    <div *ngIf="!_table.isNameEdit">{{_table.tableName}}</div>
                                    <input *ngIf="_table.isNameEdit" [(ngModel)]="_table.editedName"
                                        (blur)="tableRename(_table)" (keydown.space)="$event.stopPropagation()"
                                        (click)="$event.stopPropagation()" />
                                </div>
                                <span ngbDropdown>
                                    <button class="btn" id="dropdownBasic" ngbDropdownToggle><i
                                            class="bi bi-three-dots"></i></button>
                                    <span ngbDropdownMenu aria-labelledby="dropdownBasic">
                                        <div
                                            (click)="_table.isNameEdit = !_table.isNameEdit;_table.editedName = _table.tableName">
                                            <i class="bi bi-pencil"></i> Rename
                                        </div>
                                        <div (click)="duplicateTable(_table)">
                                            <i class="bi bi-copy"></i> Duplicate
                                        </div>
                                        <div (click)="confirm(deleteTableModel,_table.tableId)">
                                            <i class="bi bi-trash" data-bs-toggle="modal"
                                                data-bs-target="#deleteTableModel"></i> Delete
                                        </div>
                                        <div (click)="openGear(openDatasetGearModal, _table.tableId)">
                                            <i class="bi bi-gear" data-bs-toggle="modal"
                                                data-bs-target="#openDatasetGearModal"></i> Settings
                                        </div>
                                    </span>
                                </span>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="_table.loading"></span>
                            </a>
                        </section>
                    </section>
                </div>
            </div>

            <div class="detail-sidebar integrations-sidebar">
                <div class="index-item integrations" (click)="toggleSidebarItemCollapse('integrations')">
                    <lord-icon src="../../../assets/img/icons/workspace.json" trigger="hover"></lord-icon>
                    <label>Integrations</label>
                </div>
                <div class="new-workspace" data-bs-toggle="modal" data-bs-target="#openAddIntegrationModal"
                    (click)="openModal(openAddIntegrationModal)">
                    <lord-icon src="../../../assets/img/icons/network.json" trigger="hover"></lord-icon>
                    <span>Add integration</span>
                    <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>
                </div>

                <div class="integrations-list">
                    <a [routerLink]="['/analytics']">
                        <img src="../../../assets/img/integration-logos/google-analytics2.png" alt="Google Analytics" />
                        <span class="flex-grow-1">Google Analytics</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span>
                    </a>

                    <a routerLink="/ads">
                        <img src="../../../assets/img/integration-logos/google-ads.png" />
                        <span class="flex-grow-1">Google Ads</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span>
                        <i class="bi bi-exclamation-triangle text-warning" *ngIf="gOauthFlag.ads"></i>
                    </a>
                    <a routerLink="/drive">
                        <img src="../../../assets/img/integration-logos/Google_Drive.png" />
                        <span class="flex-grow-1">Google Drive</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a routerLink="/shopify">
                        <img src="../../../assets/img/integration-logos/shopify.png" />
                        <span class="flex-grow-1">Shopify</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a routerLink="/woocom">
                        <img src="../../../assets/img/integration-logos/woocommerce.png" />
                        <span class="flex-grow-1">Woo Commerce</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a routerLink="/hubspot">
                        <img src="../../../assets/img/integration-logos/hubspot.png" />
                        <span class="flex-grow-1">Hubspot</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a>
                        <img src="../../../assets/img/integration-logos/facebook.png" />
                        <span class="flex-grow-1">Facebook</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a>
                        <img style="height: 0.85rem; top: 0.7rem;"
                            src="../../../assets/img/integration-logos/meta.png" />
                        <span class="flex-grow-1">Meta</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a>
                        <img src="../../../assets/img/integration-logos/instagram.png" />
                        <span class="flex-grow-1">Instagram</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a>
                        <img src="../../../assets/img/integration-logos/linkedin.webp" />
                        <span class="flex-grow-1">LinkedIn</span>
                        <span class="gear-icon">
                            <i class="bi bi-gear"></i>
                        </span> </a>
                    <a id="add-integration" data-bs-toggle="modal" data-bs-target="#openAddIntegrationModal"
                        (click)="openModal(openAddIntegrationModal)">
                        <lord-icon src="../../../assets/img/icons/iso-plus.json" trigger="hover"></lord-icon>Add
                        integration
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                    </a>
                </div>
            </div>

            <!-- LEFT SLIDE -->
            <div #chatSlide class="chat slide" [ngClass]="signedUrlForPDF||showRawFileData ? 'min' : 'full'">
                <!-- CHAT -->
                <ai-chat></ai-chat>
                <ng-container *ngIf="signedUrlForPDF">
                    <app-spreadsheetsource [signedUrl]="signedUrlForPDF"></app-spreadsheetsource>
                </ng-container>
                <ng-container *ngIf="showRawFileData">
                    <app-spreadsheetsource [rawFileData]="dataForUploadFiles"></app-spreadsheetsource>
                </ng-container>
            </div>

            <!-- RIGHT SLIDE -->
            <div #tableSlide class="table slide">
                <div class="sub-header">
                    <div class="sub-head">
                        <div class="dropdown" *ngFor="let sheet of sheets; let i = index"
                            [attr.data-aria-labelledby]="'dropdownMenuButton_' + i">
                            <button class="btn btn-primary dropdown-toggle sheet_dd" type="button"
                                id="dropdownMenuButton_{{i}}" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" [class.loaded]="sheet.loaded"
                                (click)="switchSheetIfDifferent(sheet)">
                                <span *ngIf="!sheet.isSheetNameEdit">{{sheet.name}}</span>
                                <input *ngIf="sheet.isSheetNameEdit" [(ngModel)]="sheet.editedSheetName"
                                    (focusout)="stopEditingSheet(sheet)" (click)="$event.stopPropagation()" />
                            </button>
                            <div class="dropdown-popup" *ngIf="sheet.showDropDown">
                                <input type="file" class="file-input" (change)="uploadsheet($event, sheet)" #fileUpload>
                                <a class="dropdown-item" (click)="fileUpload.click()"><i
                                        class="bi bi-cloud-upload"></i>Import Data</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="editSheetName(sheet)"><i
                                        class="bi bi-pencil"></i>Rename sheet</a>
                                <a class="dropdown-item" (click)="duplicateSheet(sheet)"><i
                                        class="bi bi-copy"></i>Duplicate sheet</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteRecordModal"
                                    (click)="confirm(deleteModel,sheet.id)"><i class="bi bi-trash"></i>Delete sheet</a>
                            </div>
                        </div>
                        <div class="icon" id="add-sheet" (click)="createNewSheet()">
                            <lord-icon src="../../../assets/img/plus.json" trigger="hover" placement="top"
                                ngbTooltip="Create New Sheet"></lord-icon>
                        </div>
                        <!--<div class="flex-box right table-interactions">
                            <div class="csv-export" (click)="downloadCsv()">
                                <lord-icon src="../../../assets/img/google-sheet-white.json" trigger="hover"></lord-icon>CSV
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="downloadLoading.csv"></span>
                            </div>
                            <div class="excel-export" (click)="downloadXls()">
                                <lord-icon src="../../../assets/img/excel.json" trigger="hover"></lord-icon>Excel
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="downloadLoading.xls"></span>
                            </div>
                            <div class="share" (click)="resetTable()">
                                <lord-icon src="../../../assets/img/icons/share.json" trigger="hover"></lord-icon>Share
                            </div>
                        </div>-->
                    </div>
                    <div class="export-spreadsheet share-workspace" data-bs-toggle="modal"
                        (click)="alert(activeSheet?.id)" placement="left">
                        <lord-icon src="../../../assets/img/icons/share.json" trigger="hover"></lord-icon>
                    </div>
                    <div class="export-spreadsheet clean-data" data-bs-toggle="modal"
                        data-bs-target="#openDownloadModal" (click)="openModal(openDownloadModal)" placement="left">
                        <lord-icon src="../../../assets/img/icons/clean.json" trigger="hover"></lord-icon>
                    </div>
                    <div class="export-spreadsheet" data-bs-toggle="modal" data-bs-target="#openDownloadModal"
                        (click)="openModal(openDownloadModal)" placement="left">
                        <lord-icon src="https://cdn.lordicon.com/emlqoqnv.json" trigger="hover"
                            colors="primary:#000000,secondary:#000000"></lord-icon>
                    </div>
                </div>
                <div id="x-spreadsheet-demo" (click)="initXSSpreadsheetEvents()"></div>
            </div>
        </div>
    </div>
</div>

<!-- removeItemModal -->
<ng-template #deleteModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you Sure You want to Remove this Sheet?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="deleteItem(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<ng-template #deleteTableModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you Sure You want to Remove this table?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="deleteTable(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<ng-template #deleteFileModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you sure you want to remove this file?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="fileDelete(deleteId)"
                    (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- removeItemModal -->
<ng-template #deleteContainerModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you Sure You want to Remove this Folder ?</h4>
                    <p class="text-muted mx-4 mb-0">All dataset in it will be deleted.</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product"
                    (click)="deleteContainer(deleteId)" (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- dataset setting -->
<ng-template #openDatasetGearModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Settings Dataset</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product"
                    (click)="deleteContainer(deleteId)" (click)="modal.close('Close click')">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- folder settings -->
<ng-template #openFolderGearModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Settings Folder</h4>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product"
                    (click)="deleteContainer(deleteId)" (click)="modal.close('Close click')">Yes</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- Integration Modal settings -->
<ng-template #openAddIntegrationModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4>Add Integrations</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="integration-list">
                <div class="integration-item">
                    <div class="i-logo"><img src="../../../assets/img/integration-logos/google-sheets.png" /></div>
                    <div class="i-name">Google OAuth Integration</div>
                    <button class="i-authenticate"><a [href]="googleAuthUrl">Authenticate</a></button>
                </div>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

<!-- download modal -->
<ng-template #openDownloadModal let-modal>
    <div class="modal-content download-dataset">
        <div class="modal-header">
            Download Dataset
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="download-buttons">
                    <button type="button" class="btn btn-secondary" (click)="downloadXls()">
                        <lord-icon src="../../../assets/img/icons/excel.json" trigger="hover"></lord-icon>Download XLS
                    </button>
                    <button type="button" class="btn btn-secondary" (click)="downloadCsv()">
                        <lord-icon src="../../../assets/img/icons/sheet.json" trigger="hover"></lord-icon>Download CSV
                    </button>
                </div>
            </div><!--
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
            </div>-->
        </div>
    </div><!-- /.modal-content -->
</ng-template>