<header class="profile-header">cewrewrwrw
    <div class="header-content">
        <h1>Account Overview</h1>
        <div class="breadcrumb">
            <button type="button" class="btn btn-link" routerLink="/">
                <img src="../../../assets/img/icons/chevron.png" alt="Back" />
                <span>Home</span>
            </button>
            <span class="separator">/</span>
            <span class="current-page">{{ getCurrentPage() }}</span>
        </div>
    </div>
</header>

<router-outlet></router-outlet>
